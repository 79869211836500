import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

import store from '../app/store';
import { APP } from '../constants/constants';
import { logout } from '../features/auth/auth.slice';
import { acrossSessionsId, sessionId } from '../features/tracking/tracking.constants';
import { isLocal, isProd } from '../utils/env';

const API_KEY = '02dff1d6617b476dbe55970fdd5f5219';

export interface IRequestProps {
  raw?: boolean;
  originalData?: boolean;
  originalBody?: boolean;
}

export const defaultHeaders = {
  'Content-Type': 'application/json',
  'X-Platform': 'web',
  'X-Origin': APP,
  'X-Across-Sessions-Id': acrossSessionsId,
  'X-Bites-Session-Id': sessionId,
};

export const API_BASE_URL = getApiBaseUrl();

export const axiosInstance = axios.create({
  baseURL: getApiBaseUrl() + '/api',
  headers: {
    ...defaultHeaders,
    'Content-Type': 'application/json',
    'X-API-Key': API_KEY,
  },
  withCredentials: true,
});

const get = async <T>(
  path: string,
  params = {},
  axiosRequestConfig: AxiosRequestConfig = {},
  requestProps: IRequestProps = {},
): Promise<AxiosResponse<T>> => {
  try {
    const { data, ...rest } = await axiosInstance.get(path, { params, ...axiosRequestConfig });
    return {
      data: requestProps?.originalData ? data : camelCaseKeys(data, { deep: true }),
      ...rest,
    };
  } catch (error) {
    handleError(error, requestProps);
  }
};

const post = async <T>(
  path: string,
  body = {},
  axiosRequestConfig: AxiosRequestConfig = {},
  requestProps: IRequestProps = {},
): Promise<AxiosResponse<T>> => {
  try {
    const { data, ...rest } = await axiosInstance.post(
      path,
      requestProps?.originalBody ? body : snakeCaseKeys(body),
      axiosRequestConfig,
    );
    return {
      data: requestProps?.originalData ? data : camelCaseKeys(data, { deep: true }),
      ...rest,
    };
  } catch (error) {
    handleError(error, requestProps);
  }
};

const put = async <T>(
  path: string,
  body = {},
  axiosRequestConfig: AxiosRequestConfig = {},
  requestProps: IRequestProps = {},
): Promise<AxiosResponse<T>> => {
  try {
    const { data, ...rest } = await axiosInstance.put(path, snakeCaseKeys(body), axiosRequestConfig);
    return {
      data: requestProps?.originalData ? data : camelCaseKeys(data, { deep: true }),
      ...rest,
    };
  } catch (error) {
    handleError(error, requestProps);
  }
};

const patch = async <T>(
  path: string,
  body = {},
  axiosRequestConfig: AxiosRequestConfig = {},
  requestProps: IRequestProps = {},
): Promise<AxiosResponse<T>> => {
  try {
    const { data, ...rest } = await axiosInstance.patch(path, snakeCaseKeys(body), axiosRequestConfig);
    return {
      data: requestProps?.originalData ? data : camelCaseKeys(data, { deep: true }),
      ...rest,
    };
  } catch (error) {
    handleError(error, requestProps);
  }
};

const del = async <T>(
  path: string,
  body = {},
  axiosRequestConfig: AxiosRequestConfig = {},
  requestProps: IRequestProps = {},
): Promise<AxiosResponse<T>> => {
  try {
    const { data, ...rest } = await axiosInstance.delete(path, {
      data: snakeCaseKeys(body),
      ...axiosRequestConfig,
    });
    return {
      data: requestProps?.originalData ? data : camelCaseKeys(data, { deep: true }),
      ...rest,
    };
  } catch (error) {
    handleError(error, requestProps);
  }
};

const bitesApi = {
  get,
  post,
  put,
  patch,
  del,
};

export default bitesApi;

export const setBitesApiAuthorization = (token: string): void => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const resetBitesApiAuthorization = (): boolean => delete axiosInstance.defaults.headers.common.Authorization;

function getApiBaseUrl(): string {
  if (isLocal()) {
    return 'http://localhost:8000';
  }

  if (isProd()) {
    return 'https://api.mybites.io';
  }

  // we use staging for both staging and dev environments
  return 'https://api.small-bites.com';
}

function handleError(error: any, { raw }: IRequestProps = {}): void {
  if (raw) {
    throw error;
  }

  if (error?.response?.status === 401 && error?.response?.data?.message === 'Error decoding signature.') {
    store.dispatch(logout());
  }

  const message = error.response?.data?.message || error.message;
  throw new Error(message);
}
