import { makeStyles } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import React, { useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';
import { toast } from 'react-toastify';

import {
  IShareWithIntegrationResponse,
  notifyDistributionCreated,
  shareWithIntegration,
} from '../../../app/integration.api';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { selectOrg } from '../../../features/org/org.slice';
import { datadogLogger } from '../../../services/datadog/datadog';
import { EIntegrations } from '../../../types/integration';
import { getUserLocale, getUserUtcOffsetInHours } from '../../../utils/utils';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Loader from '../../Loader';
import Modal from '../../Modal';

import ChannelOrUserItem from './ChannelOrUserItem';

interface IProps {
  entity: string;
  isOpen: boolean;
  onClose: () => void;
  contentId: number;
}

function ShareWithTeamsModal({ entity, isOpen, onClose, contentId }: IProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { usersList } = useSelector(selectOrg);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [selectedChannelId, setSelectedChannelId] = useState<number>(null);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const handleShareWithTeams = useCallback(async () => {
    setIsLoading(true);
    let notificationMessageId: number;
    try {
      const shareWithIntegrationRes = await shareWithIntegration({
        [entity === 'bite' ? 'bite_share' : 'playlist']: contentId,
        users: selectedUsersIds,
        channel: selectedChannelId,
        integration: EIntegrations.Teams,
      });
      notificationMessageId = (shareWithIntegrationRes as AxiosResponse<IShareWithIntegrationResponse>)?.data
        ?.notificationMessageId;
      setSelectedUsersIds([]);
      setSelectedChannelId(null);
      setIsLoading(false);
      onClose();
      toast(t('share.successfully'));
      try {
        await notifyDistributionCreated({
          notificationMessageId,
          timezone: getUserUtcOffsetInHours(),
          locale: getUserLocale(),
        });
      } catch (e) {
        datadogLogger.error('Failed to notify distribution created', e);
      }
    } catch (error) {
      // do nothing
    } finally {
      setIsLoading(false);
    }
  }, [entity, contentId, selectedUsersIds, selectedChannelId, onClose, t]);

  const handleAccordionToggleOpen = useCallback(
    (index) => {
      const newIndex = index === openAccordionIndex ? -1 : index;
      setOpenAccordionIndex(newIndex);
    },
    [openAccordionIndex],
  );

  const handleUsersToggleOption = useCallback(
    (index) => {
      const userId = usersList[index].id;
      if (selectedUsersIds.find((selectedUserId) => userId === selectedUserId)) {
        setSelectedUsersIds(selectedUsersIds.filter((selectedUserId) => selectedUserId !== userId));
      } else {
        setSelectedUsersIds([...selectedUsersIds, userId]);
      }
    },
    [selectedUsersIds, usersList],
  );

  return (
    <Modal
      title={t('share.withTeams', { entity: entity.toUpperCase() })}
      HeaderIcon={ShareIcon}
      isOpen={isOpen}
      className={classes.modal}
      onClose={onClose}
      content={
        <div className={classes.content}>
          <div className={classes.everyoneCustom}>
            <div className={classes.headerText}>{t('share.users')}</div>
          </div>
          {!usersList && <Loader />}
          <div>
            <div className={classes.window}>
              <Scrollbar className={classes.customScrollbar}>
                {usersList?.map((data, index) => (
                  <ChannelOrUserItem
                    key={data.id}
                    isOpen={index === openAccordionIndex}
                    onToggleIsOpen={handleAccordionToggleOpen}
                    index={index}
                    selectedIds={selectedUsersIds}
                    onToggleOption={handleUsersToggleOption}
                    isLast={usersList.length - 1 === index}
                    name={data.name}
                    id={data.id}
                  />
                ))}
              </Scrollbar>
            </div>
            <div className={classes.usersCount}>{t('share.sharingWith', { count: selectedUsersIds.length })}</div>
          </div>

          {isLoading ? (
            <Loader isSmall />
          ) : (
            <PrimaryButton
              className={classes.shareButton}
              onClick={handleShareWithTeams}
              disabled={!selectedChannelId && !selectedUsersIds}
            >
              Send
            </PrimaryButton>
          )}
        </div>
      }
    />
  );
}

const useStyles = makeStyles({
  modal: {
    width: 350,
  },
  content: {
    paddingBottom: 25,
  },
  everyoneCustom: {
    height: 62,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid rgba(77, 77, 77, 0.1)',
  },
  usersCount: {
    color: '#4d4d4d',
    textAlign: 'center',
    borderTop: '1px solid rgba(77, 77, 77, 0.1)',
    padding: '23px 0',
    fontSize: 12,
  },
  shareButton: {
    margin: '0 auto',
  },
  window: {
    height: '300px',
    paddingBottom: 0,
  },
  customScrollbar: {
    '&:scrollbar': {
      display: 'none',
    },
  },
  headerText: {
    fontSize: 16,
    color: '#1253f7',
  },
});

export default memo(ShareWithTeamsModal);
