import axios from 'axios';

import { IP_BASE_TOKEN } from '../../constants/constants';
import { isProd } from '../../utils/env';

let getGeolocationPromise = null;

export const BITES_DASHBOARD_BUCKET = isProd()
  ? 'https://static.mybites.io/staticfiles/dashboard'
  : 'https://herolo-bites2-dev.s3.eu-central-1.amazonaws.com/staticfiles/dashboard';

export const loadTranslations = () => {
  return axios.get(`${BITES_DASHBOARD_BUCKET}/translations/${isProd() ? 'prod' : 'staging'}.json`, {});
};

export const getGeolocation = () => {
  getGeolocationPromise = getGeolocationPromise || axios.get(`https://api.ipbase.com/v2/info?apikey=${IP_BASE_TOKEN}`);
  return getGeolocationPromise;
};
