import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Features,
  SCHEDULE_DISTRIBUTION_DELAY_24_HOURS,
  SCHEDULE_DISTRIBUTION_DELAY_HOURS,
} from '../../../constants/constants';
import { selectOrg } from '../../../features/org/org.slice';
import { EIntegrations, IIntegrationType } from '../../../types/integration';

type TUseDistributionReminderDealyParams = {
  integration: IIntegrationType;
};

export const useDistributionReminderDealy = ({ integration }: TUseDistributionReminderDealyParams) => {
  const { t } = useTranslation();
  const org = useSelector(selectOrg);

  const delayValues = useMemo(() => {
    const is24HoursDelay = org?.features?.includes(Features['24H_REMINDER']) || integration === EIntegrations.Email;
    const delay = is24HoursDelay ? SCHEDULE_DISTRIBUTION_DELAY_24_HOURS : SCHEDULE_DISTRIBUTION_DELAY_HOURS;
    const delayTextKey = is24HoursDelay ? '24' : '2 ½';

    return { delay, delayTextKey };
  }, [integration, org?.features]);

  const text = t('share.noStatsModal.sendReminder', { delayText: delayValues.delayTextKey });

  return { delay: delayValues.delay, text };
};
