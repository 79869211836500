import { isProd } from '../utils/env';

export const FRONTLINER_URL = isProd() ? 'https://mybiteshares.com' : 'https://small-bites.com';

export const REACT_DATA_GRID_LICENSE_KEY =
  'AppName=BitesLearningApp,Company=BitesLearning,ExpiryDate=2023-09-06,LicenseDeveloperCount=1,LicenseType=single_app,Ref=BitesLearningLicenseRef,Z=719079754592594105-493784202-2084886945-15273048951543113642';
export const APP = 'dashboard';
export const MS_PERSONAL_TENANT_ID = '9188040d-6c67-4c5b-b112-36a304b66dad';

export const URL_ACCESS_TOKEN_PLACEHOLDER = 'ACCESS_TOKEN';
export const URL_ERROR_CODE_PLACEHOLDER = 'ERROR_CODE';
export const URL_ACTION_SOCIAL_AUTH = 'social-auth';
export const IP_BASE_TOKEN = '6jpbXFjnWIqxDA9QrFHqPkPpf1MkQFPs5f2w8Sz7';

export const SCHEDULE_DISTRIBUTION_DELAY_HOURS = 2.5;
export const SCHEDULE_DISTRIBUTION_DELAY_24_HOURS = 24;
export const TERMS_CONDITIONS_URL = 'https://mybites.io/termsconditions/';

//HP
export const MAIL_MERGE_ORG_IDS = [23, 4314];

export enum Features {
  SMS_DISTRIBUTION = 1,
  BEEKEEPER_DISTRIBUTION = 3,
  EMAIL_DISTRIBUTION = 682849,
  '24H_REMINDER' = 5,
}

export const BEEKEEPER_DEFAULT_MESSAGE_TEXT =
  'Hey {Full Name}!\nCheckout the new bite from {Organization Name}!\n\n{Bite Name}';

export const INTERCOM_ARTICLES = {
  howToShareContent: '9380230',
};
